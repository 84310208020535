import { motion } from "framer-motion";

function AboutSubheader() {
  
          let aboutParagraph = 'SFMERSIVE is NYC based multimedia audiovisual creative Manon Casimir-Sainton. Using her experiences with synesthesia- a heightened sensory ability to see color when hearing sound, she creates bold, resonant visualizations.'

    return (
 
        <div className="container-fluid mainBg p-5">

            <motion.h1 className="aboutDesc" 
                       initial={{ opacity:0 }} 
                       whileInView={{ opacity:1 }} 
                       transition={{ duration: 0.9, delay: 0.1 }}>
                {aboutParagraph}
            </motion.h1>


        </div>

    );
  }
  
  export default AboutSubheader;