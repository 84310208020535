import { motion } from "framer-motion";

function AboutClosure() {
  
          let aboutCloseParagraph = 'Inspired by her love of multi-sensory experiences, and her belief that being grounded in the senses improves quality of life, she works at the intersection of color, sound, movement, and psychology. SFMERSIVE collaborates with sensory brands, companies, and organizations looking to capture, inspire, empower, and mobilize clients. She has been commissioned by Columbia University, Astoria Music Project, The Well, and other NYC based organizations.'

    return (
 
        <div className="container-fluid mainBg p-5">

            <motion.h1 className="aboutDesc" 
                       initial={{ opacity:0 }} 
                       whileInView={{ opacity:1 }} 
                       transition={{ duration: 0.9, delay: 0.1 }}>
                {aboutCloseParagraph}
            </motion.h1>


        </div>

    );
  }
  
  export default AboutClosure;