import {AdvancedVideo, AdvancedImage, lazyload} from '@cloudinary/react';
import WhiteBreak from "../Spacers/WhiteBreak";
import VideoWorkHeader from "../Spacers/VideoWorkHeader"
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

  
  function ShortVideos(props) {

      const [loading, setLoading] = useState(true);
   

      useEffect(() => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      }, [])


      return (

      <div className="container-fluid-lg bgDark min-vh-100">
    
        { loading ? 
      
          <div className="spinner-wrapper">
            <AdvancedImage className='img-fluid' cldImg={props.loadViz} />
            <span className="visually-hidden">Loading...</span>
            </div> 
            :
    
    
    <div className="container-fluid shortVidPage min-vh-100">

      <VideoWorkHeader/>

<div className="row g-3">
            
            <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                          initial={{ opacity:0 }}
                          whileInView={{ opacity:1 }} 
                          transition={{ duration: 0.9, delay: 0.1 }}>
                <div className="card h-100 thumbnailCard">
                 
                <AdvancedImage className='img-fluid card-img-top' cldImg={props.mmThumb} /> 
            
                <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v5Full} plugins={[lazyload()]} controls />
                            <div className="card-body videocard ">
                              <p className="card-text text-dark" style={{fontWeight: 'bold'}}>"Make A Move" - Video Editing</p> 
                            </div>
                </div>
              </motion.div>

              <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                        initial={{ opacity:0 }}
                        whileInView={{ opacity:1 }} 
                        transition={{ duration: 0.9, delay: 0.2 }}>
              <div className="card h-100 thumbnailCard">

              <AdvancedImage className='img-fluid card-img-top' cldImg={props.ftwThumb} /> 

              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v4Full} plugins={[lazyload()]} controls />
              <div className="card-body videocard">
                            <p className="card-text text-dark" style={{fontWeight: 'bold'}}>"For the winners" - Video Editing, Sound Design</p>
                          </div>    
              </div>
            </motion.div>
  

            <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                        initial={{ opacity:0 }}
                        whileInView={{ opacity:1 }} 
                        transition={{ duration: 0.9, delay: 0.1 }}>
              <div className="card h-100 thumbnailCard">

              <AdvancedImage className='img-fluid card-img-top' cldImg={props.sbThumb} /> 

              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v2Full} plugins={[lazyload()]} controls />
                          <div className="card-body videocard">
                            <p className="card-text text-dark" style={{fontWeight: 'bold'}}>"Sensory bop" - Coding, Video Production, Video Editing, Sound Composition</p>
                          </div>
              </div>
          </motion.div>
  
            </div>



{/* <div className="row g-3">

              <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                        initial={{ opacity:0 }}
                        whileInView={{ opacity:1 }} 
                        transition={{ duration: 0.9, delay: 0.1 }}>
              <div className="card">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v2Full} controls />
                          <div className="card-body videocard">
                            <p className="card-text text-white">"Sensory bop" - Coding, Video Production, Video Editing, Sound Composition</p>
                          </div>
              </div>
          </motion.div>
            
            <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                          initial={{ opacity:0 }}
                          whileInView={{ opacity:1 }} 
                          transition={{ duration: 0.9, delay: 0.2 }}>
                <div className="card">
                <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v3Full} controls />
                            <div className="card-body videocard">
                              <p className="card-text text-white">"The Vote" (Teaser)</p>
                            </div>
                </div>
              </motion.div>
  
            <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-5"
                         initial={{ opacity:0 }}
                         whileInView={{ opacity:1 }} 
                         transition={{ duration: 0.9, delay: 0.3 }}>
                <div className="card wrapper">         
                <iframe className='img-fluid' src="https://www.youtube.com/embed/zkZ7RmQGTS8?si=F4ka9G9WRTamC0EZ" frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            <div className="card-body videocard">
                              <p className="card-text text-white">"The Vote" (Long Form) - Video Editing, Script, Sound Composition</p>
                            </div>
                </div>
            </motion.div>
  
            </div> */}

        </div>}
        <WhiteBreak /> 
      </div>
     
      );
    }
    
    export default ShortVideos;