import { Link } from "react-router-dom";


function NavBar() {

    return (

    <nav className="navbar navbar-expand-sm navbar-expand-md navbar-expand-lg sticky-top ">

    <div className="container-fluid">

      <Link to="/" className="navbar-brand text-white ps-3">
                SFMERSIVE
      </Link>

      <button className="navbar-toggler btn-outline-dark" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-theme="dark"
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation">
         <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse text-end center-text-div" id="navbarSupportedContent">
        <ul className="navbar-nav inline-block">

          <li className="nav-item">
          <Link to="/multimedia" 
                className="navbar-link-style">
                Work
          </Link>
          </li>

          <li className="nav-item">
          <Link to="/speaking" 
                className="navbar-link-style">
                Speaking
          </Link>
          </li>

          <li className="nav-item">
          <Link to="/meet" 
                className="navbar-link-style">
                About
          </Link>
          </li>

          <li className="nav-item">
          <Link to="/contact" 
                className="navbar-link-style">
                Contact
          </Link>
          </li>
         
        </ul>
      </div>

    </div>
  </nav>

     
    );
  }
  
  export default NavBar;