import Values from "./Values";
import CollaborateFor from "./CollaborateFor"
import Footer from "../Footer";
import WhiteBreak from "../Spacers/WhiteBreak";
import NavBar from "../NavBar";
import ValuesHeader from "../Spacers/ValuesHeader";
import AboutHeader from "../Spacers/AboutHeader";
import AboutClosure from "../Spacers/AboutClosure";
import {AdvancedImage} from '@cloudinary/react';
import { motion } from "framer-motion";


function AboutPage(props) {

  let aboutMainParagraph1 = 'At the start of my creative journey, I began abstract painting and producing music. I put experiences of a type of synesthesia I had, seeing colors in sound, on canvas. I had my artwork shown in a number of galleries in New York and beyond. I loved meeting and collaborating with many types of people. Eventually, I transitioned to creating digital art. I loved making things move on large screens and the public was really receptive to it.'

  let aboutMainParagraph2 = 'Fast forward to today, making audiovisual content is my home. I use the power of storytelling, audiovisual design, and emotion to teach, engage, and interact.'


    return (
 
      <div className="mainBg">

        <NavBar />

        <br/>
        <AboutHeader />
      
            <div className="container-fluid row">
           
              <motion.div   initial={{ opacity:0 }} 
                            animate={{ opacity:1 }} 
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-5"
                            >
                              
              <motion.h3 className="text-primary">
              
              {aboutMainParagraph1}

              <br/>
              <br/>

              {aboutMainParagraph2}

              <br/>
              <br/>
               
               </motion.h3>
              </motion.div>

              <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div">
              <AdvancedImage className='img-fluid about_Portrait' 
                                  cldImg={props.aboutPortrait} />
              </motion.div>

            </div>
   
            <AboutClosure/>
          <CollaborateFor collabVid1={props.collabVid1}
                          collabSnd2={props.collabSnd2}
                          collabMulti3={props.collabMulti3}
                          collabDig4={props.collabDig4}
                          collabPort5={props.collabPort5}
                          cVariants={props.cVariants}
                          iVariants={props.iVariants}/>
          <ValuesHeader />
          <Values  cVariants={props.cVariants}
                   iVariants={props.iVariants}/>
          <WhiteBreak/>
          <Footer/>
      </div>
      
    );
  }
  
  export default AboutPage;
  