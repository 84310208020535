import { motion } from "framer-motion";

function VideoWorkHeader() {
  
    return (
 
        <div className="container-fluid-lg min-vh-100 center-text-div mainBg">

            <motion.h2 className="videowork-header" 
                       initial={{ opacity:0 }} 
                       whileInView={{ opacity:1 }} 
                       transition={{ duration: 0.9, delay: 0.1 }}>
                Videos
            </motion.h2>

        </div>

    );
  }
  
  export default VideoWorkHeader;