import { Link } from "react-router-dom";
import {AdvancedImage} from '@cloudinary/react';
import { motion } from "framer-motion";


function SpeakingMain(props) {

    return (
 
        <div className="container-fluid speakingmain">
          
          <div className='row'>

              <motion.div className='col-lg  center-text-div p-5'
                    variants={props.vVariants}
                    initial={{ opacity: 0.5}}
                     whileInView='show'>

                <div className="container card p-3 mx-auto" 
                     style={{background: 'black', border: 'none'}}>

                  <div className="card-body">
                      <h6 className="card-text mb-2 mainFont-header keepword">
                            Talking about <span className="synest">synesthesia</span>, multisensory wellness, and film
                      </h6>

                  <br/> 

                        <div className="text-center">
                          <Link to="/speaking" >
                              <motion.button className='btn btn-outline-light btnLight btn-md border-3 rounded-pill'
                                             whileHover={{ scale: 1.1 }}
                                             whileTap={{ scale: 0.3}}
                                             transition={{bounceDamping: 5}}>
                                              Learn more
                              </motion.button>
                          </Link>
                        </div>
                  </div>
            </div>
        </motion.div>

  <motion.div className='col-lg  center-text-div p-5'
        variants={props.vVariants}
        initial={{ opacity: 0.5}}
        whileInView='show'>
      <div className="container-fluid mx-auto" style={{border: 'none'}}>
      <AdvancedImage  cldImg={props.speakMainImg} 
                      className="img-fluid" 
                      alt="..." 
                      style={{width: '100%', 
                              borderRadius: '10px'}}/>
     </div>
  </motion.div>

</div> 
</div> 
    );
  }
  
  export default SpeakingMain;