import Typewriter from 'typewriter-effect';
// import { motion } from "framer-motion";

function VideomainHeader() {


    return (
 
        <div className="container-fluid  p-3">

            
<h1 className="text-white typeWriter-header center-text-div pt-5 pb-5">
                       Creating visuals you can 

    <Typewriter onInit={(typewriter) => {
        typewriter.typeString('<span style="color: magenta;">absorb.</span>')
          .callFunction(() => {
          })
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: orange;">feel.</span>')
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: blue;">taste.</span>')
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: violet;">hear.</span>')
          .pauseFor(2500)
          .deleteAll()
          .callFunction(() => {
          })
          .start();
      }}
        options={{
          autoStart: true,
          loop: true,
        }}
        wrapperClassName='typewriter-wrapper'
      />
    
            </h1>
     
        </div>
    );
  }
  
  export default VideomainHeader;