import { Link } from "react-router-dom";
import Footer from "../Footer";
import {AdvancedImage} from '@cloudinary/react';
import NavBar from "../NavBar";
import WhiteBreak from "../Spacers/WhiteBreak";
import { motion } from "framer-motion";

function SpeakerPage(props) {

    return ( 
 
    <div className="container-fluid-lg min-vh-100 speakingmain">

      <NavBar />

        <div className="bkrnd-visual-wrapper">

          <AdvancedImage className='bkrnd-visual_gif' cldImg={props.speakerViz} />
            
            <div className="container card-img-overlay position-absolute center-text-div">
            <motion.div   initial={{ opacity:0 }} 
                          animate={{ opacity:1 }} 
                          transition={{ duration: 0.9, delay: 0.1 }}>
                <h6 className="card-text mainFont-header p-5">
                    SFMERSIVE speaks about synesthesia and the impact of multisensory experiences on mental health and wellness. An audiovisual creator and lover, she has spoken as a panelist, moderator, and emCee. 
                </h6>
                </motion.div>
                    {/* <br/> */}

              <Link to="/contact">
                <motion.button className='btn btn-outline-light btnLight btn-lg border-3 rounded-pill'
                               whileHover={{ scale: 1.1 }}
                               whileTap={{ scale: 0.3}}
                               transition={{bounceDamping: 5}}>  
                              Request
                </motion.button>
              </Link>
            
            </div>
        </div>
        <WhiteBreak /> 
        <Footer /> 
</div> 

        
    



   
    


    );
  }
  
  export default SpeakerPage;